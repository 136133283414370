import React from "react";
import "./footer.css";


const Footer = () => {
  return(
    <section id="footer">Vienna, 2023 | <a href="#/impressum">Impressum</a></section>
  )};
  
export default Footer;
